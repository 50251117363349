/**
 * 学校管理api
 */
export default {
  // 学校添加
  schoolAdd: '/schoolInfo/add',
  // 学校修改
  schoolAudit: '/schoolInfo/audit',
  // 学校删除
  schoolDelete: '/schoolInfo/delete',
  // 学校列表
  schoolList: '/schoolInfo/list',
  // 学校查看详情
  schoolDetail: '/schoolInfo/queryDetail',
};
