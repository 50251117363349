// 门户管理
export default [

  {
    path: '/portalManagement',
    meta: { title: '市场竞争监管' },
    component: () => import('../views/portalManagement/index.vue'),
    // redirect: '/portalManagement/competition',
    children: [
      {
        path: '/portalManagement/competition',
        meta: { title: '市场竞争监管', isMenu: true },
        component: () => import('../views/portalManagement/competition.vue'),
      },
      {
        path: '/portalManagement/investmentPromotion',
        meta: { title: '招商引资', isMenu: true },
        component: () => import('../views/portalManagement/investmentPromotion.vue'),
      },
      {
        path: '/portalManagement/informationDisclosure',
        meta: { title: '信息公开', isMenu: true },
        component: () => import('../views/portalManagement/informationDisclosure.vue'),
      },
      {
        path: '/portalManagement/detail/demo',
        meta: { title: '新增', },
        component: () => import('../views/portalManagement/detail/demo.vue'),
      },
    ],
  },
];
