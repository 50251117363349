/**   
 * 骑手管理api
 */
export default {
  /**
    * 骑手列表
    */
  // 列表
  getRiderUserList: '/riderUser/listPage',
  // 查看详情
  getRiderUserDetails: '/riderUser/check',
  // 审核
  auditingRiderUserStatus: '/riderUser/auditing',

};