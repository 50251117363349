// 经营信息
export default [
  {
    path: '/business',
    meta: { title: '经营信息' },
    component: () => import('../views/business/index.vue'),
    redirect: '/business/financial',
    children: [
      {
        path: '/business/financial',
        meta: { title: '财务信息', isMenu: true },
        component: () => import('../views/business/financial.vue'),
      },
    ],
  },
]