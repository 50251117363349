// 商超管理
export default [
  {
    path: '/supermarket',
    meta: { title: '商超管理' },
    component: () => import('../views/supermarket/index.vue'),
    // redirect: '/supermarket/list',
    children: [
      {
        path: '/supermarket/list',
        meta: { title: '商超列表', isMenu: true },
        component: () => import('../views/supermarket/list.vue'),
      },
      {
        path: '/supermarket/examine',
        meta: { title: '商超审核', isMenu: true },
        component: () => import('../views/supermarket/examine.vue'),
      },
      {
        path: '/supermarket/detail/add',
        meta: { title: '添加商超' },
        component: () => import('../views/supermarket/detail/add.vue'),
      },
      {
        path: '/supermarket/detail/edit',
        meta: { title: '修改商超信息' },
        component: () => import('../views/supermarket/detail/edit.vue'),
      },
      {
        path: '/supermarket/detail/info',
        meta: { title: '查看商超信息' },
        component: () => import('../views/supermarket/detail/info.vue'),
      },
      {
        path: '/supermarket/banner',
        meta: { title: '商城轮播图', isMenu: true },
        component: () => import('../views/supermarket/banner.vue'),
      },
      {
        path: '/supermarket/statistics',
        meta: { title: '商超统计', isMenu: true },
        component: () => import('../views/supermarket/statistics.vue'),
      },
      {
        path: '/supermarket/inventoryReview',
        meta: { title: '库存审核', isMenu: true },
        component: () => import('../views/supermarket/inventoryReview.vue'),
      },
      {
        path: '/supermarket/detail/inventoryDetail',
        meta: { title: '库存审核查看'},
        component: () => import('../views/supermarket/detail/inventoryDetail.vue'),
      },
    ],
  },
];
