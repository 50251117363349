// 供应商管理
export default [
  {
    path: "/supplier",
    meta: { title: "供应商管理" },
    component: () => import("../views/supplier/index.vue"),
    // redirect: '/supplier/list',
    children: [
      {
        path: "/supplier/list",
        meta: { title: "供应商列表", isMenu: true },
        component: () => import("../views/supplier/list.vue"),
      },
      {
        path: "/supplier/statistics",
        meta: { title: "供应商统计", isMenu: true },
        component: () => import("../views/supplier/statistics.vue"),
      },
      {
        path: "/supplier/winBid",
        meta: { title: "中标供应商", isMenu: true },
        component: () => import("../views/supplier/winBid.vue"),
      },
      {
        path: "/supplier/winBidProduct",
        meta: { title: "中标商品", isMenu: true },
        component: () => import("../views/supplier/winBidProduct.vue"),
      },
      // {
      //   path: "/supplier/examine",
      //   meta: { title: "供应商审核", isMenu: true },
      //   component: () => import("../views/supplier/examine.vue"),
      // },
      {
        path: "/supplier/detail/supplierDetail",
        meta: { title: "供应商详情" },
        component: () => import("../views/supplier/detail/supplierDetail.vue"),
      },
      {
        path: "/supplier/detail/examineDetail",
        meta: { title: "供应商审核" },
        component: () => import("../views/supplier/detail/examineDetail.vue"),
      },
      {
        path: "/supplier/detail/supplyList",
        meta: { title: "供货关系" },
        component: () => import("../views/supplier/detail/supplyList.vue"),
      },
      {
        path: "/supplier/detail/shopList",
        meta: { title: "查看商超" },
        component: () => import("../views/supplier/detail/shopList.vue"),
      },
      // {
      //   path: "/supplier/detail/productBidList",
      //   meta: { title: "中标商品列表" },
      //   component: () => import("../views/supplier/detail/productBidList.vue"),
      // },
    ],
  },
]
