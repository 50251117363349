/**
 * 合同管理api
 */
export default {
  // 合同模板
  // 合同模板列表
  templateList: "/contractTemplate/contractTemplateList",
  // {id}删除模板
  delTemplate: "/contractTemplate/deleteContractTemplate/",
  // 新增模板
  uploadTemplate: "/contractTemplate/uploadContractTemplate",

  // 合同列表
  // {id}合同状态(确定按钮)
  contractConfirm: "/contractInfo/contractConfirm/",
  // {id}合同撤回
  contractRetract: "/contractInfo/contractRetract/",
  // 合同列表
  contractsList: "/contractInfo/contractsList",
  // 查询标书供应商
  getsupplierTenders: "/contractInfo/getSupplierByTenders",
  // 查询未生成完合同的标书
  getTenders: "/contractInfo/getTenders",
  // {id}平台签署
  platformsign: "/contractInfo/platformSign/",
  // 生成合同
  createdcontract: "/contractInfo/createdContract",
  // 预览合同
  viewContract: "/contractInfo/viewContract",

  createSignature: "/signatureInfo/createSignature", // 创建签章
  deleteSignatureInfo: "/signatureInfo/deleteSignatureInfo", // 删除签章
  insertSignatureInfo: "/signatureInfo/insertSignatureInfo", // 新增签章
  selectSignatureInfoDetail: "/signatureInfo/selectSignatureInfoDetail", // 签章详情
  selectSignatureInfoList: "/signatureInfo/selectSignatureInfoList", // 签章列表
  selectSignatureTypeList: "/signatureInfo/selectSignatureTypeList", // 查询签章类型
  updateSignatureInfo: "/signatureInfo/updateSignatureInfo", // 修改签章

  signTendersContract: "/contractInfo/signTendersContract", // 签署投标合同
  createTendersContract: "/contractInfo/createTendersContract", // 生成中标合同：一键生成合同supplierIdList参数不送
  contractTendersList: "/contractInfo/contractTendersList", // 标书-合同列表
}
