/**
 * 调价管理api
 */
export default {
  // 调价审核-审核
  priceAudit: '/adjustPriceReview/audit',
  // 调价审核列表
  priceList: '/adjustPriceReview/list',
  // 调价详情
  priceDetail: '/adjustPriceReview/queryDetail',
};
