/**
 * 招标管理api
 */
export default {
  // 招标管理>招标列表>添加
  tendersInfoAdd: '/tendersInfo/add',

  //招标管理>招标信息>标期下拉列表
  bidPeriodPage: 'tendersInfo/bidPeriodPage',

  // 招标管理>招标列表>删除 {tendersId}
  tendersInfoDelete: '/tendersInfo/delete',

  // 招标管理>招标列表>查看 {tendersId}
  tendersInfoDetails: '/tendersInfo/portalDetails',

  // 招标管理>招标信息
  portalPageList: '/tendersInfo/portalPageList',

  // 招标管理 > 招标列表 > 修改
  tendersInfoUpdate: '/tendersInfo/update',

  // 招标管理>评标列表
  portalEvaluationPageList: '/tendersInfo/portalEvaluationPageList',
  //一个分类查询商品列表
  categoryIdProductInfoList: '/productInfoSupplier/categoryIdProductInfoList',
  //获取当前时间的应标资格
  selectNowTendersEligible: '/tendersEligible/selectNowTendersEligible',

  /**
   * 投标报名api
   */
  //招标管理>报名列表
  portalApplicationPageList: '/tendersApplication/portalApplicationPageList',

  // 招标管理>报名审核
  portalExaminePageList: '/tendersApplication/portalExaminePageList',

  // 招标管理>报名审核>查看{applicationId}
  portalDetails: '/tendersApplication/portalDetails',

  // 招标管理>报名审核>通过/不通过
  examine: '/tendersApplication/examine',

  /**
   * 投标报名api
   */

  // 新增自动评标管理
  add: '/tenders_automatic/add',

  // 删除自动评标管理
  deleted: '/tenders_automatic/deleted',

  // 自动评标管理列
  tenders_automatic_list: '/tenders_automatic/tenders_automatic_list',

  // 修改自动评标管理
  update: '/tenders_automatic/update',

  /**
   * 评标列表api
   */
  // 评标列表
  list: '/evaluation/list',

  // 评标商品详情列表
  evaluation_info: '/evaluation/evaluation_info',

  // 提交中标商品
  update_tenders_product: '/evaluation/update_tenders_product',

  // 一键评标
  one_evaluation: '/tenders_info_new/allEvaluation',

  /**
   * 中标列表api
   */

  // 中标列表
  tenders_list: '/tenders_list/list',

  // 中标列表详情
  list_info: '/tenders_list/list_info',

  /**
   * 商品分类api
   */
  // 投标商品列表
  tendersProductVoPageResult: '/productInfoSupplier/tendersProductVoPageResult',

  tenders_info_newBid_periods: '/tenders_info_new/bid_periods', // 标期列表

  tenders_automaticSelect_tender_name: '/tenders_automatic/select_tender_name', // 根据标号查询标书名称

  tenders_info_newOneEvaluation: '/tenders_info_new/oneEvaluation', // 评标

  // 一键确认
  confirmAll: '/tenders_list_new/confirmAll',
  // 价格调整
  changeSupplyPrice: '/tenders_list_new/changeSupplyPrice',
  // 平台供货价确认状态
  confirmSupplyPrice: '/tenders_list_new/confirm',
};
