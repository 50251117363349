/**
 * 公共api
 */
export default {
  //登录
  login: "/userLogin/login",
  //文件上传，返回地址
  uploadFile: "/attachment/uploadFile",
  //获取短信验证码
  SMSCode: "/userLogin/SMSCode",
  //提交短信验证码
  CheckSMSCode: "/userLogin/CheckSMSCode",
  //修改密码
  retrievePassword: "/userLogin/retrievePassword",
  // 查询省市区
  getDistrictInfo: "/districtInfo/getDistrictInfo",
  // 查询省市
  getSecondDistrictInfo: "/districtInfo/getSecondDistrictInfo",

  /**
   * 第三方聚合支付api
   */
  // 获取授权code
  thirdOauthCode: "https://dev-longlon.eshare168.com/r/sso/oauth/code",
  // 通过临时token调用API短信接口
  thirdCaptchaSms: "https://dev-longlon.eshare168.com/r/sso/oauth/captchaSms",
  // 获取授权token
  thirdOauthLogin: "https://dev-longlon.eshare168.com/r/sso/oauth/login",

  // pcLogin:'/tencentIm/pcLogin', // pc登录im
  loginIM: "/tencentIm/login", // 登录腾讯im
}
