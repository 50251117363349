/**   
 * 系统管理api
 */
export default {
  /**
   * 用户信息
   */
  getSysUserList: "/sysUser/getSysUserList", //列表
  getSysUserById: "/sysUser/getSysUserById", //详情/{userId}
  addSysUser: "/sysUser/addSysUser", //新增
  updateSysUser: "/sysUser/updateSysUser", //修改
  deleteSysUser: "/sysUser/deleteSysUser", //删除/{userId}

  /**
  * 基本信息
  */
  getInformation: "/sysUser/getInformation", // 查询个人信息
  updateInformation: "/sysUser/updateInformation", // 修改个人信息
  updatePassword: "/sysUser/updatePassword", // 修改密码

  /**
   * 部门/组织信息
   */
  getSysDepartmentList: "/sysDepartment/getSysDepartmentList", //列表
  getSysDepartment: "/sysDepartment/getSysDepartment", //详情/{departmentId}
  addSysDepartment: "/sysDepartment/addSysDepartment", //新增
  updateSysDepartment: "/sysDepartment/updateSysDepartment", //修改
  deleteSysDepartment: "/sysDepartment/deleteSysDepartment", //删除/{departmentId}

  /**
   * 角色信息
   */
  getSysRoleList: "/sysRole/getSysRoleList", //列表
  getSysRole: "/sysRole/getSysRole", //详情/{roleId}
  addSysRole: "/sysRole/addSysRole", //新增
  updateSysRole: "/sysRole/updateSysRole", //修改
  deleteSysRole: "/sysRole/deleteSysRole", //删除/{roleId}

  /**
   * 菜单信息
   */
  getSysMenuList: "/sysMenu/getSysMenuList", //列表
  getSysMenu: "/sysMenu/getSysMenu", //详情/{menuId}
  addSysMenu: "/sysMenu/addSysMenu", //新增
  updateSysMenu: "/sysMenu/updateSysMenu", //修改
  deleteSysMenu: "/sysMenu/deleteSysMenu", //删除/{menuId}

  /**
   * 用户角色授权信息
   */
  getSysUserRoleList: "/sysUserRole/getSysUserRoleList", //列表
  addSysUserRole: "/sysUserRole/addSysUserRole", //新增
  deleteSysUserRole: "/sysUserRole/deleteSysUserRole", //取消授权
};