// 招标管理
export default [
  {
    path: '/biddingManagement',
    meta: { title: '招标管理' },
    component: () => import('../views/biddingManagement/index.vue'),
    // redirect: '/biddingManagement/bidInformation',
    children: [
      {
        path: '/biddingManagement/bidInformation',
        meta: { title: '招标列表', isMenu: true },
        component: () =>
          import('../views/biddingManagement/bidInformation.vue'),
      },
      {
        path: '/biddingManagement/bidList',
        meta: { title: '评标审议', isMenu: true },
        component: () => import('../views/biddingManagement/bidList.vue'),
      },
      {
        path: '/biddingManagement/applicationExamine',
        meta: { title: '资格审查', isMenu: true },
        component: () =>
          import('../views/biddingManagement/applicationExamine.vue'),
      },
      {
        path: '/biddingManagement/applicationList',
        meta: { title: '应标级别', isMenu: true },
        component: () =>
          import('../views/biddingManagement/applicationList.vue'),
      },
      {
        path: '/biddingManagement/bidder',
        meta: { title: '中标列表', isMenu: true },
      },
      {
        path: '/biddingManagement/bidder/winning',
        meta: { title: '中标结果', isMenu: true },
        component: () =>
          import('../views/biddingManagement/winning.vue'),
      },
      {
        path: '/biddingManagement/bidder/productMark',
        meta: { title: '加价审核', isMenu: true },
        component: () =>
          import('../views/biddingManagement/productMark.vue'),
      },
      {
        path: '/biddingManagement/detail/productMarkDetail',
        meta: { title: '商品加价查看'},
        component: () =>
          import('../views/biddingManagement/detail/productMarkDetail.vue'),
      },

      // {
      //   path: '/biddingManagement/bidEvaluationList',
      //   meta: { title: '评标列表', isMenu: true },
      //   component: () =>
      //     import('../views/biddingManagement/bidEvaluationList.vue'),
      // },

      // {
      //   path: '/biddingManagement/automaticBidEvaluationList',
      //   meta: { title: '自动评标列表', isMenu: true },
      //   component: () =>
      //     import('../views/biddingManagement/automaticBidEvaluationList.vue'),
      // },
      {
        path: '/biddingManagement/detail/winningBidManagement',
        meta: { title: '中标结果查看' },
        component: () =>
          import('../views/biddingManagement/detail/winningBidManagement.vue'),
      },
      {
        path: '/biddingManagement/detail/bidListDetails',
        meta: { title: '招标管理详情' },
        component: () =>
          import('../views/biddingManagement/detail/bidListDetails.vue'),
      },
      {
        path: '/biddingManagement/detail/winningBidDetails',
        meta: { title: '中标列表详情' },
        component: () =>
          import('../views/biddingManagement/detail/winningBidDetails.vue'),
      },
      {
        path: '/biddingManagement/detail/applicationDetails',
        meta: { title: '报名审核详情' },
        component: () =>
          import('../views/biddingManagement/detail/applicationDetails.vue'),
      },
      {
        path: '/biddingManagement/detail/bidEvaluationListDetails',
        meta: { title: '评标列表详情' },
        component: () =>
          import(
            '../views/biddingManagement/detail/bidEvaluationListDetails.vue'
          ),
      },
      {
        path: '/biddingManagement/detail/bidEvaluationTable',
        meta: { title: '评标列表详情' },
        component: () =>
          import('../views/biddingManagement/detail/bidEvaluationTable.vue'),
      },
      {
        path: '/biddingManagement/detail/add',
        meta: { title: '新增' },
        component: () => import('../views/biddingManagement/detail/add.vue'),
      },
    ],
  },
];
