// 合同管理
export default [
  {
    path: "/contract",
    meta: { title: "合同管理" },
    component: () => import("../views/contract/index.vue"),
    // redirect: '/contract/contractTemplate',
    children: [
      {
        path: "/contract/contractTemplate",
        meta: { title: "合同模板", isMenu: true },
        component: () => import("../views/contract/contractTemplate.vue"),
      },
      {
        path: "/contract/contractBidList",
        meta: { title: "合同列表", isMenu: true },
        component: () => import("../views/contract/contractBidList.vue"),
      },
      {
        path: "/contract/detail/contractList",
        meta: { title: "合同列表" },
        component: () => import("../views/contract/detail/contractList.vue"),
      },
      {
        path: "/contract/contracSeal",
        meta: { title: "签章管理", isMenu: true },
        component: () => import("../views/contract/contracSeal.vue"),
      },
      {
        path: "/contract/detail/add",
        meta: { title: "新建合同" },
        component: () => import("../views/contract/detail/add.vue"),
      },
      {
        path: "/contract/detail/newBuilt",
        meta: { title: "新建合同" },
        component: () => import("../views/contract/detail/newBuilt.vue"),
      },
      {
        path: "/contract/detail/preview",
        meta: { title: "查看合同" },
        component: () => import("../views/contract/detail/preview.vue"),
      },
      {
        path: "/contract/detail/sign",
        meta: { title: "签署合同" },
        component: () => import("../views/contract/detail/sign.vue"),
      },
    ],
  },
]
