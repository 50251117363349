/**
 * 招标管理--中标api
 */
export default {
  /**
   * 中标列表api
   */
  // 中标列表
  tenders_list_new: '/tenders_list_new/list',

  // 中标列表详情
  list_info_new: '/tenders_list_new/list_info',

  // 预警审核
  warning_review: '/tenders_list_new/warning_review',

  /**
   * 招标列表api
   */
  //   招标列表查看

  tenders_info_new: '/tenders_info_new/portal_select_list',

  // 招标列表
  portalPageList_new: '/tenders_info_new/portalPageList',

  // 招标列表评标
  tender_evaluation: '/tenders_info_new/tender_list_evaluation',

  // 投标资格管理新增
  tendersEligibleAdd: '/tendersEligible/add',

  // 投标资格管理删除
  tendersEligibleAudit: '/tendersEligible/audit',

  // 投标资格管理查询
  tendersEligible: '/tendersEligible/select',

  /**
   * 应标审核api
   */
  // 列表
  getPortalBiddingEligibleList: '/biddingReview/portalBiddingEligibleList',
  // 应标审核详情
  getBiddingDetail: '/biddingReview/biddingDetail',
  // 修改状态
  changeBiddingEdit: '/biddingReview/biddingEdit',
  // 修改缴费截止时间
  deadlinePayTime: '/tendersInfo/update_deadlinePayTime',
  // 中标商品作废
  bad_product: '/tenders_info_new/bad_product',
  // 全选
  allPortalPageList: '/tenders_info_new/allPortalPageList',
};
