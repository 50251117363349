// 联系客服
export default [

  {
    path: '/customerService',
    meta: { title: '咨询服务' },
    component: () => import('../views/customerService/index.vue'),
    // redirect: '/customerService/list',
    children: [
      // {
      //   path: '/customerService/consultation',
      //   meta: { title: '咨询列表', isMenu: true },
      //   component: () => import('../views/customerService/consultation.vue'),
      // },
      {
        path: '/customerService/list',
        meta: { title: '客服管理', isMenu: true },
        component: () => import('../views/customerService/list.vue'),
      },
      {
        path: '/customerService/sendAll',
        meta: { title: '消息群发', isMenu: true },
        component: () => import('../views/customerService/sendAll.vue'),
      },
      {
        path: '/customerService/detail/add',
        meta: { title: '消息群发' },
        component: () => import('../views/customerService/detail/add.vue'),
      },
    ],
  },
];
