// 系统管理
export default [
  {
    path: '/system',
    meta: { title: '系统管理' },
    component: () => import('../views/system/index.vue'),
    // redirect: '/system/user',
    children: [
      {
        path: '/system/user',
        meta: { title: '用户管理', isMenu: true },
        component: () => import('../views/system/user.vue'),
      },
      {
        path: '/system/role',
        meta: { title: '角色管理', isMenu: true },
        component: () => import('../views/system/role.vue'),
      },
      {
        path: '/system/menu',
        meta: { title: '菜单管理', isMenu: true },
        component: () => import('../views/system/menu.vue'),
      },
      {
        path: '/system/tissue',
        meta: { title: '组织管理', isMenu: true },
        component: () => import('../views/system/tissue.vue'),
      },
      {
        path: '/system/detail/associatedUsers',
        meta: { title: '关联用户' },
        component: () => import('../views/system/detail/associatedUsers.vue'),
      },
    ],
  },
]