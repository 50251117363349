/**
 * 接口统一管理
 */
import common from './common.js';
import system from './system.js';
import supplier from './supplier.js';
import bid from './bid.js';
import productMgt from './productMgt.js';
import expert from './expert.js';
import gateway from './gateway.js';
import bidder from './bidder.js';
import customerService from './customerService.js';
import supermarket from './supermarket.js';
import school from './school.js';
import adjustPrice from './adjustPrice.js';
import contract from './contract.js';
import settlement from './settlement.js';
import rider from './rider.js';
import technicalServiceFee from './technicalServiceFee.js';
import business from './business.js';

const apiAll = [
  supplier,
  system,
  common,
  bid,
  productMgt,
  expert,
  gateway,
  bidder,
  customerService,
  supermarket,
  school,
  adjustPrice,
  contract,
  settlement,
  rider,
  technicalServiceFee,
  business,
];

// 判断接口是否已存在
function isKeyQepeat(arr) {
  let keyArr = [],
    apiObj = {};
  arr.forEach((item) => {
    keyArr.push(...Object.keys(item));
    Object.assign(apiObj, item);
  });
  try {
    keyArr.forEach((item, i, arr) => {
      if (arr.indexOf(item) !== i) {
        throw new Error(`接口 key 重复项: ${item}`);
      }
    });
  } catch (error) {
    console.error('[错误]:', error);
    return null;
  }
  return apiObj;
}
const apiDesc = isKeyQepeat(apiAll);
//进行统一导出
export default {
  ...apiDesc,
};
