// 学校管理
export default [
  {
    path: '/school',
    meta: { title: '学校管理' },
    component: () => import('../views/school/index.vue'),
    // redirect: '/school/list',
    children: [
      {
        path: '/school/list',
        meta: { title: '学校列表', isMenu: true },
        component: () => import('../views/school/list.vue'),
      },
      {
        path: '/school/detail/add',
        meta: { title: '添加学校' },
        component: () => import('../views/school/detail/add.vue'),
      },
      {
        path: '/school/detail/edit',
        meta: { title: '修改学校信息' },
        component: () => import('../views/school/detail/edit.vue'),
      },
      {
        path: '/school/detail/info',
        meta: { title: '查看学校信息' },
        component: () => import('../views/school/detail/info.vue'),
      },
    ],
  },
] 