// 专家库管理
export default [
  {
    path: '/expertDatabase',
    meta: { title: '专家库' },
    component: () => import('../views/expertDatabase/index.vue'),
    // redirect: '/expertDatabase/expertDatabase',
    children: [
      {
        path: '/expertDatabase/expertDatabase',
        meta: { title: '专家库', isMenu: true },
        component: () => import('../views/expertDatabase/expertDatabase.vue'),
      },

    ],
  },
];
