// 骑手管理
export default [
  {
    path: '/rider',
    meta: { title: '骑手管理' },
    component: () => import('../views/rider/index.vue'),
    // redirect: '/rider/bikerList',
    children: [
      {
        path: '/rider/bikerList',
        meta: { title: '骑手列表', isMenu: true },
        component: () => import('../views/rider/bikerList.vue'),
      },

      {
        path: '/rider/detail/riderDetail',
        meta: { title: '骑手详情' },
        component: () => import('../views/rider/detail/riderDetail.vue'),
      },

    ],
  },
]