// 调价管理
export default [
  {
    path: '/adjustPrice',
    meta: { title: '调价管理' },
    component: () => import('../views/adjustPrice/index.vue'),
    // redirect: '/adjustPrice/list',
    children: [
      {
        path: '/adjustPrice/list',
        meta: { title: '调价审核', isMenu: true },
        component: () => import('../views/adjustPrice/list.vue'),
      },
      {
        path: '/adjustPrice/detail/add',
        meta: { title: '' },
        component: () => import('../views/adjustPrice/detail/add.vue'),
      },
      {
        path: '/adjustPrice/detail/edit',
        meta: { title: '' },
        component: () => import('../views/adjustPrice/detail/edit.vue'),
      },
      {
        path: '/adjustPrice/detail/info',
        meta: { title: '调价审核详情' },
        component: () => import('../views/adjustPrice/detail/info.vue'),
      },
    ],
  },
];
